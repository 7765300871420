import {TableBase} from "../../tables/table_base";

class VehicleUsage extends TableBase {
  constructor() {
    super('vehicle_usage');
  }

  paintTable($vehicleUsage, vehicle_usage) {
    $vehicleUsage.empty()
    let hasRows = false;
    for (const reg in vehicle_usage) {
      hasRows = true;
      const id = vehicle_usage[reg].id,
        mileage = vehicle_usage[reg].mileage,
        usage = vehicle_usage[reg].usage;

      const $row = $('<tr>');
      const $infoCell = $('<span>').attr({'data-tbl': 'veh', 'data-id': id}).addClass('hover-preview').text(reg);
      const $regCell = $('<td>').append($infoCell);
      const $mileageCell = $('<td>').text(mileage.toLocaleString());
      const $usageCell = $('<td>').text(app.DASHBOARD_CORE.format_duration(usage));
      $row.append($regCell, $mileageCell, $usageCell);
      $vehicleUsage.append($row);
    }

    if( !hasRows ) {
      $vehicleUsage.html('<tr><td colspan="5">No vehicle usage to display</td></tr>');
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new VehicleUsage();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
